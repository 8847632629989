<template>
  <div class="menu-link-drop-down-cdg">
    <b-dropdown
      id="dropdown-top-menu-cdg"
      :class="{ 'active-dropdown-top-menu-cdg': computedActiveLink }"
      right
      variant="none"
    >
      <template #button-content>
        <div class="button-vox-style">{{ title }}</div>
      </template>
      <div
        class="menu-link resize-width-menu-item"
        v-for="(item, index) in items"
        :key="title + 'item' + index"
      >
        <b-dropdown-item
          :href="item.href"
          v-if="computedCoesCheck(item.code)"
          :class="{
            'router-link-active':
              (!item.urls && item.href == $route.path) ||
              (item.urls && item.urls.includes($route.path))
          }"
        >
          {{ item.name }}
        </b-dropdown-item>

        <!-- <b-dropdown-divider
          v-if="computedCoesCheck(item.code)"
        ></b-dropdown-divider> -->
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    title: { required: true },
    items: { required: true }
  },
  computed: {
    ...mapGetters(['checkPermission']),
    computedActiveLink() {
      let tab = this.items.filter(
        item =>
          (!item.urls && item.href == this.$route.path) ||
          (item.urls && item.urls.includes(this.$route.path))
      );
      if (tab.length > 0) {
        return true;
      }
      return false;
    },
    computedSubActiveLink() {
      return function(item) {
        return item.href == this.$route.path;
      };
    },
    computedCoesCheck() {
      return function(coeds) {
        if (typeof coeds == 'string') {
          return this.checkPermission(coeds);
        } else {
          let data = false;
          coeds.forEach(element => {
            if (this.checkPermission(element)) {
              data = true;
            }
          });
          return data;
        }
      };
    }
  },
  mounted() {}
};
</script>
<style lang="scss">
.menu-link-drop-down-cdg {
  margin: 1px 0px;
  margin-right: 4px;
  .active-dropdown-top-menu-cdg {
    .dropdown-toggle::after,
    button .button-vox-style {
      color: #fff !important;
    }
    button {
      background-color: #4d4bac;
    }
  }
  #dropdown-top-menu-cdg {
    button {
      border-radius: 25px;
      display: flex;
      line-height: 16px;
      padding: 0px;
      .button-vox-style {
        display: block;
        overflow: hidden;
        font-weight: 600;
        padding: 12px 8px;
        font-size: 11px;
        line-height: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #5d5d5d;
        border-radius: 25px;
        margin-right: 4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .dropdown-toggle::after {
      color: #4d4bac;
      top: 13px;
      right: 11px;
      position: relative;
    }
    &:hover {
      .dropdown-toggle::after,
      button .button-vox-style {
        color: #fff;
      }
      button {
        background-color: #9799d6;
      }
    }
    .dropdown-menu {
      padding: 3px 0px;
      background: #e9e4ffeb;
      // top: -18px !important;
    }
    .dropdown-menu {
      margin: 0;
      border-radius: 0;
      border-radius: 16px;
      box-shadow: 0px 3px 6px #aaaaaa42;
      border: none;
      transform: translate3d(-9px, 34px, 0px) !important;
      li {
        margin: 0px 5px;
      }
      .dropdown-item {
        border-radius: 25px;
        margin: 1px 0px;
        padding: 6px 8px;
        display: block;
        overflow: hidden;
        font-weight: 600;
        padding: 12px 8px;
        font-size: 11px;
        line-height: 7px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #5d5d5d;
        border-radius: 25px;
        margin-right: 4px;
        font-family: 'Montserrat', sans-serif;
        &:hover {
          color: #fff;
          background-color: #9799d6;
          border-radius: 25px;
        }
      }
      .router-link-active {
        color: #fff;
        background-color: #4d4bac;
        border-radius: 25px;
        a {
          color: #fff;
        }
      }
    }
  }
  // .resize-width-menu-item {
  //   margin-left: 14px;
  // }
}
</style>
