<template>
  <div class="menu">
    <router-link to="/insertion-bdd" v-if="checkPermission('IBD')">
      Insertion BDD
    </router-link>

    <router-link v-if="checkPermission('A&VTH')" to="/rapportTh">
      Analyse et validation
    </router-link>
    <router-link
      v-if="checkPermission('FOTH') && checkPermission('GAPTH')"
      :to="
        isSuperAdminGetter
          ? '/obligee/gestion-appels-paiement-obligee'
          : '/obligee'
      "
      :class="{
        'router-link-active': ComputedRouterLinkObligeeActive,
        a: !ComputedRouterLinkObligeeActive
      }"
    >
      Obligés
    </router-link>
    <custom-drop-down-menu
      v-if="computedShowFactureMenu"
      title="Factures"
      :items="itemsFactures"
    ></custom-drop-down-menu>

    <router-link to="/comptabilite" v-if="checkPermission('COTH')">
      Comptabilité
    </router-link>

    <router-link to="/reglement-facture" v-if="checkPermission('RFTH')">
      Règlement Factures
      <b-badge
        class="custom-badge-cdg"
        v-if="checkPermission('RFTH')"
        title="paiement non lettré"
        >{{ getBadgeReglements }}</b-badge
      >
    </router-link>
    <router-link to="/refresh-fature" v-if="checkPermission('REFFTH')">
      Refresh factures
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import customDropDownMenu from '../component/customDropDownMenu.vue';
export default {
  components: { customDropDownMenu },
  computed: {
    ...mapGetters([
      'getUserData',
      'checkPermission',
      'isSuperAdminGetter',
      'isAdminRegie',
      'isCommercialRegie',
      'getBadgeReglements'
    ]),
    itemsFactures() {
      return [
        {
          name: 'Gestion des frais',
          href: this.computedRouterFrais,
          code: ['GDFTH', 'SFTH', 'GGDFTH'],
          urls: [
            '/frais-th/simulation-facture-frais',
            '/frais-th/vision-globale-frais',
            '/frais-th'
          ]
        },
        {
          name: 'Factures libres',
          href: '/facture-libre',
          code: 'FL'
        },
        {
          name: 'Factures SCI',
          href: '/facture-sci',
          code: 'FSTH'
        },
        {
          name: 'Gestion factures mensuelles',
          href: '/gestion-facture-mensuel',
          code: 'GFMTH'
        },
        {
          name: 'Gestion master filliale',
          href: '/master-filiale-2',
          code: 'GMFTH'
        },
        {
          name: 'Factures commerciaux',
          href: this.computedFacturesCommerciaux,
          code: ['FC', 'FCTH', 'GAPC'],
          urls: [
            '/commercial/appel-paiement',
            '/commercial/facture-commercial',
            '/commercial'
          ]
        }
      ];
    },
    computedShowFactureMenu() {
      return (
        this.checkPermission('FL') ||
        this.checkPermission('FSTH') ||
        this.checkPermission('GMFTH') ||
        this.checkPermission('GFMTH') ||
        this.ComputedRouterLinkFraisThShow
      );
    },
    ComputedRouterLinkObligeeActive: function() {
      return this.$route.name === 'gestionAppelPaiementObligee';
    },

    ComputedRouterLinkFraisThShow: function() {
      return (
        this.checkPermission('GDFTH') &&
        (this.checkPermission('SFTH') ||
          this.checkPermission('GGDFTH') ||
          // this.checkPermission('GMFTH') ||
          // this.checkPermission('GMFTH2') ||
          this.checkPermission('GCTH') ||
          this.checkPermission('GRHTH'))
        // || this.checkPermission('GFMTH')
      );
    },
    computedFacturesCommerciaux: function() {
      if (this.checkPermission('GAPC')) {
        return '/commercial/appel-paiement';
      }
      if (this.checkPermission('FCTH')) {
        return '/commercial/facture-commercial';
      }
      return '/commercial';
    },
    computedRouterFrais: function() {
      if (this.checkPermission('SFTH')) {
        return '/frais-th/simulation-facture-frais';
      }
      if (this.checkPermission('GGDFTH')) {
        return '/frais-th/vision-globale-frais';
      }
      return '/frais-th';
    }
  }
};
</script>

<style lang="scss" scoped>
.menu > .Search-style-component {
  margin: auto !important;
}
.menu {
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 100%;
  a {
    display: block;
    overflow: hidden;
    font-weight: 600;
    padding: 12px 8px;
    font-size: 11px;
    line-height: 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #5d5d5d;
    border-radius: 25px;
    margin-right: 4px;
    font-family: 'Montserrat', sans-serif;

    &.router-link-active {
      color: #fff;

      background-color: #4d4bac;
    }
    &:hover {
      color: #fff;
      background-color: #9799d6;
    }
  }

  .custom-dropdown {
    height: 31px;
    margin-right: 4px;
  }
}
@media screen and (max-width: 1200px) {
  .menu {
    display: inline;
    a {
      margin: 0px 5px;
    }
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 1200px) {
  .menu .custom-dropdown {
    height: 42px !important;
    button {
      text-align: start;
    }
  }
}
.custom-dropdown {
  .custom-dropdown-button {
    height: 31px;
    background-color: transparent;
    border: none;
    color: #5d5d5d;
    border-radius: 25px;
    margin-right: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 12px;
    font-size: 14px !important;
    line-height: 7px !important;
    &:hover {
      color: #fff;
      background-color: #9799d6;
    }
    &.btn-,
    &.btn-secondary:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: #4d4bac;
    }
  }
  &.router-link-active {
    background-color: #4d4bac;
    border-radius: 25px;
    button {
      color: white;
    }
    &.custom-dropdown-button {
      color: white;
    }
  }
  .dropdown-menu {
    margin: 0;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #aaaaaa42;
    border: none;
    transform: translate3d(0px, 34px, 0px) !important;
    li {
      margin: 0px 5px;
    }
    .dropdown-item {
      border-radius: 25px;
      margin: 1px 0px;
      padding: 6px 8px;

      &.router-link-active {
        color: #fff;
        background-color: #4d4bac;
      }
      &:active {
        background-color: #4d4bac;
      }
      &:hover {
        color: #fff;
        background-color: #9799d6;
        border-radius: 25px;
      }
    }
  }
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #4d4bac;
  height: 31px;
}
</style>
